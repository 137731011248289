import reportWebVitals from './reportWebVitals';
import React from 'react';
import ReactDOM from "react-dom/client";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Auth from "./Authentication/auth";
import "./index.scss";
import Loader from "./shade/Loaders/Loaders"
import IsSignedIn from "./guards/IsSignedIn";
import IsGuest from "./guards/IsGuest";

const App = React.lazy(() => import("../src/shade/layouts/App"));

const Widgets = React.lazy(() =>
    import("./components/Widgets/Widgets"))

const AuthLogin = React.lazy(() => import("./Authentication/Login"));
//Form
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.Fragment>
        <BrowserRouter>
            <React.Suspense fallback={<Loader/>}>
                <Routes>
                    <Route path={`${process.env.PUBLIC_URL}/login`} element={<IsGuest to={'/'}><Auth/></IsGuest>}>
                        <Route index element={<AuthLogin/>}/>
                    </Route>

                    <Route path={`${process.env.PUBLIC_URL}/`} element={<IsSignedIn to={'/login'}><App/></IsSignedIn>}>
                        <Route
                            path={`${process.env.PUBLIC_URL}/`}
                            element={<Widgets/>}
                        />
                    </Route>
                    <Route path="*" element={<Navigate to={'/'}/>}/>
                </Routes>
            </React.Suspense>
        </BrowserRouter>
    </React.Fragment>
);

reportWebVitals();
